import { useParams } from "react-router-dom";
import NavBar from "../../NavBar/NavBar";
import NeedConnected from "../../NeedConnected/NeedConnected";
import { useEffect, useState } from "react";
import { getGainById } from "../../../utils/requests/Gains";
import { updateGain } from "../../../utils/requests/Gains";
import { uploadImg } from "../../../utils/requests/Gains";

export function EditGain(){
    const {gainId} = useParams();
    const [gain, setGain] = useState(null);
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState('');
    const [name, setName] = useState('');
    const [qts, setQts] = useState(0);
    const [max_by_day, setMaxByDay] = useState(0);
    const [lang, setLang] = useState('');
    const [file, setFile] = useState(null);
    const [lundi, setLundi] = useState(0);
    const [mardi, setMardi] = useState(0);
    const [mercredi, setMercredi] = useState(0);
    const [jeudi, setJeudi] = useState(0);
    const [vendredi, setVendredi] = useState(0);
    const [samedi, setSamedi] = useState(0);
    const [dimanche, setDimanche] = useState(0);
    const [by_day_diff, setByDayDiff] = useState(0);
    const [by_hours, setByHours] = useState(0);
    const [disponibility_hours, setdisponibility_hours] = useState(null);

    const loadGain = async () => {
        const res = await getGainById(gainId);
        if(res.success){
            setGain(res.data);
            setName(res.data.name);
            setQts(res.data.qts);
            setMaxByDay(res.data.max_by_day);
            setLundi(res.data.lundi);
            setMardi(res.data.mardi);
            setMercredi(res.data.mercredi);
            setJeudi(res.data.jeudi);
            setVendredi(res.data.vendredi);
            setSamedi(res.data.samedi);
            setDimanche(res.data.dimanche);
            setByDayDiff(res.data.by_day_diff);
            setByHours(res.data.by_hours);
            setdisponibility_hours(JSON.parse(res.data.disponibility_hours));
        }else{
            setMsg(res.msg);
        }
        console.log(res);
    }

    const upload = async () =>{
        const res = await uploadImg(file, lang, gainId);
        if(res.success){
            setMsg('Image upload avec success');
        }else{
            setMsg(res.msg);
        }
    }

    useEffect(()=>{
        loadGain();
        setTimeout(()=>{
            setLoading(false);
        }, 300);
    },[]);

    return(
        <>
            <NeedConnected/>
            <NavBar/>
            <div className="container mt-5 p-4">
                {
                    (loading) ? 
                    <h1 className="text-center">
                        Chargement...
                    </h1>
                    :
                    (gain == null) ?
                    <div className="text-center">
                        <span className="err">{msg}</span>
                    </div>
                    :
                    <>
                        <div className="row">
                            <div className="col col-3">
                                <span>
                                    ID du gain :
                                </span>
                            </div>
                            <div className="col col-9">
                                <span>
                                    {gain.id}
                                </span>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col col-3">
                                <span>
                                    Nom du gain : 
                                </span>
                            </div>
                            <div className="col col-9">
                                <input type="text" className="form-control" value={name} onChange={(e)=>{setName(e.target.value)}} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Qts en stock
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setQts(e.target.value)}} type="number" className="form-control" value={qts} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Quantité par jours (0 pour sans limite)
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setMaxByDay(e.target.value)}} type="number" className="form-control" value={max_by_day} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-6">
                            <div className="row">
                            <div className="col col-9 mt-3">
                                <span>
                                    Quantité différente dans la semaine
                                </span>
                            </div>
                            <div className="col col-3 mt-3">
                                <input checked={by_day_diff} onChange={(e)=>{setByDayDiff(e.target.checked ? 1 : 0)}} type="checkbox"/>
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Lundi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setLundi(e.target.value)}} type="number" className="form-control" value={lundi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Mardi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setMardi(e.target.value)}} type="number" className="form-control" value={mardi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Mercredi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setMercredi(e.target.value)}} type="number" className="form-control" value={mercredi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Jeudi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setJeudi(e.target.value)}} type="number" className="form-control" value={jeudi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Vendredi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setVendredi(e.target.value)}} type="number" className="form-control" value={vendredi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Samedi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setSamedi(e.target.value)}} type="number" className="form-control" value={samedi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Dimanche
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setDimanche(e.target.value)}} type="number" className="form-control" value={dimanche} />
                            </div>
                            </div>
                            </div>
                            <div className="col col-6">
                                <div className="row mt-3">
                                    <div className="col col-9">
                                        <span>
                                            Quantité répartir par heure
                                        </span>
                                    </div>
                                    <div className="col col-3">
                                        <input onChange={(e)=>{setByHours(e.target.checked ? 1 : 0)}} checked={by_hours} type="checkbox" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        00-01
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h0: e.target.value})}} value={disponibility_hours ? disponibility_hours.h0 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        01-02
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h1: e.target.value})}} value={disponibility_hours ? disponibility_hours.h1 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        02-03
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h2: e.target.value})}} value={disponibility_hours ? disponibility_hours.h2 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        03-04
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h3: e.target.value})}} value={disponibility_hours ? disponibility_hours.h3 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        04-05
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h4: e.target.value})}} value={disponibility_hours ? disponibility_hours.h4 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        05-06
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h5: e.target.value})}} value={disponibility_hours ? disponibility_hours.h5 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        06-07
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h6: e.target.value})}} value={disponibility_hours ? disponibility_hours.h6 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        07-08
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h7: e.target.value})}} value={disponibility_hours ? disponibility_hours.h7 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        08-09
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h8: e.target.value})}} value={disponibility_hours ? disponibility_hours.h8 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        09-10
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h9: e.target.value})}} value={disponibility_hours ? disponibility_hours.h9 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        10-11
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h10: e.target.value})}} value={disponibility_hours ? disponibility_hours.h10 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        11-12
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h11: e.target.value})}} value={disponibility_hours ? disponibility_hours.h11 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        12-13
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h12: e.target.value})}} value={disponibility_hours ? disponibility_hours.h12 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        13-14
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h13: e.target.value})}} value={disponibility_hours ? disponibility_hours.h13 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        14-15
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h14: e.target.value})}} value={disponibility_hours ? disponibility_hours.h14 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        15-16
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h15: e.target.value})}} value={disponibility_hours ? disponibility_hours.h15 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        16-17
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h16: e.target.value})}} value={disponibility_hours ? disponibility_hours.h16 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        17-18
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h17: e.target.value})}} value={disponibility_hours ? disponibility_hours.h17 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        18-19
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h18: e.target.value})}} value={disponibility_hours ? disponibility_hours.h18 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        19-20
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h19: e.target.value})}} value={disponibility_hours ? disponibility_hours.h19 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        20-21
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h20: e.target.value})}} value={disponibility_hours ? disponibility_hours.h20 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        21-22
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h21: e.target.value})}} value={disponibility_hours ? disponibility_hours.h21 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        22-23
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h22: e.target.value})}} value={disponibility_hours ? disponibility_hours.h22 : 0} type="number" className="form-control" />
                                    </div>
                                    <div className="col col-3 mt-3">
                                        23-00
                                    </div>
                                    <div className="col col-9 mt-3">
                                        <input onChange={(e)=>{setdisponibility_hours({...disponibility_hours, h23: e.target.value})}} value={disponibility_hours ? disponibility_hours.h23 : 0} type="number" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label htmlFor="img" className="mt-3">
                            <input onChange={(e)=>{setFile(e.target.files[0])}} type="file" id="img" />
                        </label>
                        <select onChange={(e)=>{setLang(e.target.value)}}>
                            <option value="">-- Langue --</option>
                            <option value="fr">-- FR --</option>
                            <option value="nl">-- NL --</option>
                            <option value="en">-- EN --</option>
                        </select>
                        <button className="btn btn-success" onClick={()=>{upload()}}>
                            Publier
                        </button>
                        <button onClick={()=>{updateGain(gain.id, name, qts, max_by_day, lundi, mardi, mercredi, jeudi, vendredi, samedi, dimanche, by_day_diff, by_hours, JSON.stringify(disponibility_hours))}} className="form-control mt-3">Mettre à jours</button>
                    </>
                }
            </div>
        </>
    )
}